import React from "react";
import { Button, Flex, Row, Segmented, Typography } from "antd";
import { Link } from "react-router-dom";
import { routes } from "../../routes";
import { isMobile } from "react-device-detect";
import { DaySelect } from "../../components/day-select";
import { PageSpinner } from "../../components/page-spinner";
import { ContentCategoryEnum } from "../../types/content-type.enum";
import { QuestionCard } from "../../components/question/question-card";
import { BibleCard } from "../../components/question/bible-card";
import { StudentCard } from "../../components/student/student-card";
import {useMutation, useQuery} from "@tanstack/react-query";
import axios from "axios";
import {apiUrl} from "../../api/requests";
import {Question} from "../../types/question";
import {Student} from "../../types/student";

const { Title } = Typography;

export const StudentsList = () => {
  return <div></div>
  //
  // const {
  //   mutate: getStudents,
  //   isPending,
  //   error,
  //   data,
  // } = useMutation({
  //   retry: false,
  //   mutationFn: async (day?: number) => {
  //     const result = await axios.get(`${apiUrl}/students`);
  //     return result.data as Question[];
  //   },
  // });
  //
  // const {
  //   data,
  //   isPending,
  //   isError
  // } = useQuery({
  //   queryKey: ["students"],
  //   queryFn: async () => {
  //     const result = await axios.get(`${apiUrl}/questions/`);
  //     return result.data as Student[];
  //   },
  //
  // });
  //
  // return (
  //   <Flex gap="middle" vertical>
  //     <Flex gap="middle" justify="space-between">
  //       <Title level={3} style={{ margin: 0 }}>
  //         Студенты
  //       </Title>
  //     </Flex>
  //     {/*<Flex gap="middle" vertical={isMobile}>*/}
  //     {/*  <Segmented*/}
  //     {/*    value={translation}*/}
  //     {/*    size="large"*/}
  //     {/*    options={translations}*/}
  //     {/*    onChange={(value) => setTranslation(value.toString())}*/}
  //     {/*  />*/}
  //     {/*  <DaySelect value={selectedDay} onChange={onChangeDay} />*/}
  //     {/*</Flex>*/}
  //     <Row gutter={[14, 24]}>
  //       {isPending && !!selectedDay ? (
  //         <PageSpinner />
  //       ) : (
  //         data?.map((s) => <StudentCard key={s.id} student={s} />)
  //       )}
  //     </Row>
  //   </Flex>
  // );
};
