import React, { useMemo, useState } from "react";
import { Button, Flex, Row, Segmented, Typography } from "antd";
import { useMutation } from "@tanstack/react-query";
import { Error } from "../../components/error";
import { Question } from "../../types/question";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { apiUrl } from "../../api/requests";
import axios from "axios";
import { PageSpinner } from "../../components/page-spinner";
import { DaySelect } from "../../components/day-select";
import { SegmentedLabeledOption } from "rc-segmented";
import {
  ContentCategoryEnum,
  ContentTypeEnum,
} from "../../types/content-type.enum";
import { BibleCard } from "../../components/question/bible-card";
import { QuestionCard } from "../../components/question/question-card";
import { isMobile } from "react-device-detect";

const { Title } = Typography;

export const QuestionList: React.FC = () => {
  const navigate = useNavigate();
  const [selectedDay, setSelectedDay] = useState<number | undefined>();
  const [translation, setTranslation] = useState(
    ContentTypeEnum.rst.toString(),
  );

  const {
    mutate: getQuestions,
    isPending,
    error,
    data,
  } = useMutation({
    retry: false,
    mutationFn: async (day: number) => {
      const result = await axios.get(`${apiUrl}/questions`, {
        params: { day_number: day },
      });
      return result.data as Question[];
    },
  });

  const transformedData = useMemo(() => {
    return data
      ?.filter((q) => {
        if (q.content_category === ContentCategoryEnum.question) return true;
        return q.content_variety === translation;
      })
      .sort((a, b) => a.content_ordering_value - b.content_ordering_value);
  }, [data, translation]);

  const { mutate: deleteQuestion } = useMutation({
    mutationFn: (questionId: number) =>
      axios.delete(`${apiUrl}/questions/${questionId}`),
    onSuccess: () => getQuestions(selectedDay!),
  });

  if (error) {
    return (
      <Error title="Ошибка" subTitle="Запрос на сервер выполнен неудачно" />
    );
  }

  const onEdit = (id: number) => {
    navigate(generatePath(routes.questionEdit, { id }));
  };

  const onDelete = (id: number) => {
    deleteQuestion(id);
  };

  const onChangeDay = (day: number) => {
    setSelectedDay(day);
    getQuestions(day);
  };

  const translations: SegmentedLabeledOption[] = [
    { value: ContentTypeEnum.rst, label: "Синодальный" },
    { value: ContentTypeEnum.nrt, label: "НРП" },
  ];

  return (
    <Flex gap="middle" vertical>
      <Flex gap="middle" justify="space-between">
        <Title level={3} style={{ margin: 0 }}>
          Вопросы
        </Title>
        <Link to={routes.questionCreate}>
          <Button type="primary">Добавить</Button>
        </Link>
      </Flex>
      <Flex gap="middle" vertical={isMobile}>
        <Segmented
          value={translation}
          size="large"
          options={translations}
          onChange={(value) => setTranslation(value.toString())}
        />
        <DaySelect value={selectedDay} onChange={onChangeDay} />
      </Flex>
      <Row gutter={[14, 24]}>
        {isPending && !!selectedDay ? (
          <PageSpinner />
        ) : (
          transformedData?.map((q) => {
            return q.content_category === ContentCategoryEnum.question ? (
              <QuestionCard
                key={q.id}
                question={q}
                handleDelete={onDelete}
                handleEdit={onEdit}
              />
            ) : (
              <BibleCard key={q.id} question={q} />
            );
          })
        )}
      </Row>
      {/*<Table dataSource={dataSource} columns={columns} />*/}
    </Flex>
  );
};
