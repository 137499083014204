import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import "./index.css";
import { router } from "./routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { App } from "antd";
import { CookiesProvider } from "react-cookie";
import axios from "axios";
import Cookies from "js-cookie";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const queryClient = new QueryClient();

axios.interceptors.request.use((config) => {
  const authId = Cookies.get('auth_id');
  const token = Cookies.get('token');

  if (authId && token) {
    config.headers.set('auth-id', authId);
    config.headers.set('auth-token', token);
  }

  return config;
});

root.render(
  <React.StrictMode>
    <CookiesProvider>
      <App>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </App>
    </CookiesProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
