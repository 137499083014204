import React from "react";
import { Button, Result } from "antd";

export const Success: React.FC<{
  title: string;
  subTitle?: string;
  onClickButton: () => void;
  buttonTitle: string;
}> = ({ title, subTitle, onClickButton, buttonTitle }) => (
  <Result
    status="success"
    title={title}
    subTitle={subTitle}
    extra={[
      <Button type="primary" key="console" onClick={onClickButton}>
        {buttonTitle}
      </Button>,
    ]}
  ></Result>
);
