import React from "react";
import { Card, Col, Flex, Tag } from "antd";
import { EditOutlined } from "@ant-design/icons";
import {
  ContentCategoryEnum,
  ContentTypeEnum,
  QuestionTypeTitleEnum,
} from "../../types/content-type.enum";
import { Question } from "../../types/question";
import { DeleteQuestionButton } from "./delete-question-button";
import { cardSizes } from "./constants";

type QuestionCardProps = {
  question: Question;
  handleEdit: (id: number) => void;
  handleDelete: (id: number) => void;
};

export const QuestionCard: React.FC<QuestionCardProps> = ({
  question,
  handleDelete,
  handleEdit,
}) => {
  const getTagColor = (v: string) => {
    switch (v) {
      case ContentTypeEnum.advanced:
        return "red";
      case ContentTypeEnum.beginner:
      default:
        return "blue";
    }
  };

  const actions =
    question.content_category === ContentCategoryEnum.question
      ? [
          <EditOutlined key="edit" onClick={() => handleEdit(question.id)} />,
          <DeleteQuestionButton
            handleDelete={() => handleDelete(question.id)}
          />,
        ]
      : [];

  return (
    <Col {...cardSizes}>
      <Card
        title={`Вопрос №${question.content_ordering_value}`}
        actions={actions}
      >
        <Flex gap="middle" vertical>
          <div>{question.content_value}</div>
          <div>Порядковый номер: {question.content_ordering_value}</div>

          <Tag bordered={false} color={getTagColor(question.content_variety)}>
            {QuestionTypeTitleEnum[question.content_variety]}
          </Tag>
        </Flex>
      </Card>
    </Col>
  );
};
