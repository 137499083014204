import { createBrowserRouter } from "react-router-dom";
import { Main } from "./pages/Main/Main";
import { QuestionList } from "./pages/questions/question-list";
import { MainLayout } from "./components/main-layout";
import { AddQuestion } from "./pages/questions/add-question";
import { EditQuestion } from "./pages/questions/edit-question";
import { SignIn } from "./pages/auth/sign-in";
import {StudentsList} from "./pages/students/students-list";

export const routes = {
  signIn: "/sign-in",
  questions: "/questions",
  questionCreate: "/question/create",
  questionEdit: "/question/edit/:id",
  students: "/students",
};

export const router = createBrowserRouter([
  {
    path: "/sign-in",
    element: <SignIn />,
  },
  {
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Main />,
      },
      {
        path: routes.questions,
        element: <QuestionList />,
      },
      {
        path: routes.questionCreate,
        element: <AddQuestion />,
      },
      {
        path: routes.questionEdit,
        element: <EditQuestion />,
      },
      {
        path: routes.students,
        element: <StudentsList />,
      },
    ],
  },
]);
