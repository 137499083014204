import React from "react";
import { Card, Col, Flex, Tag } from "antd";
import { QuestionTypeTitleEnum } from "../../types/content-type.enum";
import { Question } from "../../types/question";
import { cardSizes } from "./constants";

type QuestionCardProps = {
  question: Question;
};

export const BibleCard: React.FC<QuestionCardProps> = ({ question }) => {
  return (
    <Col {...cardSizes}>
      <Card title="Текст Библии">
        <Flex gap="middle" vertical>
          <div>{question.content_value}</div>
          <Tag bordered={false} color="blue">
            {QuestionTypeTitleEnum[question.content_variety]}
          </Tag>
        </Flex>
      </Card>
    </Col>
  );
};
