export enum ContentTypeEnum {
  advanced = 'advanced',
  beginner = 'beginner',
  rst = 'rst',
  nrt = 'nrt',
}

export enum ContentCategoryEnum {
  question = 'question',
  bible = 'bible',
}

export enum QuestionTypeTitleEnum {
  advanced = 'Опытный',
  beginner = 'Новичок',
  rst = 'Синодальный перевод',
  nrt = 'Новый русский перевод',
}
