import React, { useState } from "react";
import { Button, Popover } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";

type DeleteQuestionButtonProps = {
  handleDelete: () => void;
};

export const DeleteQuestionButton: React.FC<DeleteQuestionButtonProps> = ({
  handleDelete,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <Popover
      content={
        <Button type="link" onClick={handleDelete}>
          Удалить
        </Button>
      }
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <DeleteTwoTone key="delete" twoToneColor="red" />
    </Popover>
  );
};
