import React from "react";
import { Button, Form, FormInstance, Input, Radio } from "antd";
import { ContentTypeEnum } from "../../types/content-type.enum";
import { QuestionCreate } from "../../pages/questions/add-question";
import {DaySelect} from "../day-select";

const { TextArea } = Input;

export const QuestionForm: React.FC<{
  form: FormInstance<QuestionCreate>;
  handleSubmit: (question: QuestionCreate) => void;
  isDisabled?: boolean;
  isEdit?: boolean;
}> = ({ form, handleSubmit, isDisabled, isEdit }) => {
  return (
    <Form
      layout="vertical"
      form={form}
      style={{ maxWidth: 600 }}
      onFinish={handleSubmit}
      disabled={isDisabled}
    >
      <Form.Item label="День" name="day_number" rules={[{ required: true }]}>
        <DaySelect />
      </Form.Item>

      <Form.Item
        label="Контент"
        name="content_value"
        rules={[{ required: true }]}
      >
        <TextArea rows={4} maxLength={500} />
      </Form.Item>

      <Form.Item
        rules={[{ required: true }]}
        label="Сложность"
        name="content_variety"
      >
        <Radio.Group value={"horizontal"}>
          <Radio.Button value={ContentTypeEnum.beginner}>Новичок</Radio.Button>
          <Radio.Button value={ContentTypeEnum.advanced}>Опытный</Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        rules={[{ required: true }]}
        label="Порядковый номер"
        name="content_ordering_value"
      >
        <Input type="number" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {isEdit ? 'Изменить' : 'Добавить'}
        </Button>
      </Form.Item>
    </Form>
  );
};
