import React from "react";
import { Button, Result } from "antd";

export const Error: React.FC<{
  title: string;
  subTitle: string;
  hasButton?: boolean;
  onClickButton?: () => void;
  buttonTitle?: string;
}> = ({ title, subTitle, hasButton, onClickButton, buttonTitle }) => (
  <Result
    status="error"
    title={title}
    subTitle={subTitle}
    extra={[
      hasButton && (
        <Button type="primary" key="console" onClick={onClickButton}>
          {buttonTitle}
        </Button>
      ),
    ]}
  ></Result>
);
