import React from "react";
import { Spinner } from "./spinner";

export const PageSpinner: React.FC = () => (
  <div
    style={{
      height: "55vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Spinner />
  </div>
);
